import type { ConfigInterface } from '@/services/interfaces';

export const config: ConfigInterface = {
  helpCenterPageUrl: 'https://help.timecamp.com/help',
  kioskPageUrl: 'https://kiosk.timecamp.io/',
  kioskHelpPage: '/kiosk',
  feedbackModalTagId: '44ab48d3-027f-40dc-9d6d-ab4a302f6a1f',
  feedbackWeeklyBugModalTagId: '7cb4e218-0170-4181-8c83-1490c620e859',
  feedbackWeeklyFeedbackModalTagId: 'addad32a-3aaa-4169-a6ac-38343ae4d3db',
  feedbackKioskBugModalTagId: '46f237bc-8d75-410c-9bda-251468f15cfe',
  feedbackKioskFeedbackModalTagId: 'b493ee01-98c1-4950-90ae-8264adaf0e79',
  feedbackAssistantModalTagId: 'aa9ec6b4-1054-472d-94ff-b91f55f34920',
  feedbackExpensesBugModalTagId: '4494411e-3b9b-4700-b0bc-dab5e0c62e77',
  feedbackExpensesFeedbackModalTagId: '6571f0c6-4876-4686-9d5e-abda4f2f1844',
  timezoneOffset: 0,
  storage: 'LocalStorage',
  colors: [
    {
      name: 'Lime',
      value: '#77C903',
    },
    {
      name: 'Emerald',
      value: '#5CCB72',
    },
    {
      name: 'Teal',
      value: '#7AD2B9',
    },
    {
      name: 'Cyan',
      value: '#50A5A6',
    },
    {
      name: 'Sky',
      value: '#4DC2E8',
    },
    {
      name: 'Azure',
      value: '#0E7FF8',
    },
    {
      name: 'Midnight',
      value: '#175799',
    },
    {
      name: 'Violet',
      value: '#5B4D99',
    },
    {
      name: 'Fuchsia',
      value: '#A12687',
    },
    {
      name: 'Rose',
      value: '#D96481',
    },
    {
      name: 'Wine',
      value: '#BE2D33',
    },
    {
      name: 'Peanut',
      value: '#8B6A49',
    },
    {
      name: 'Corn',
      value: '#E0C600',
    },
    {
      name: 'Honey',
      value: '#F6B042',
    },
    {
      name: 'Gray',
      value: '#8A8A8A',
    },
  ],
  ...window.REACT_CONFIG,
};
